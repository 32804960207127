<template>
  <div class="zq--table-footer" :class="{'zq-hide': disabled}">
    <div class="text-center text-lg-left mb-2">
      <div class="d-flex align-items-center">{{ $t('tableFooter.info.showing') }} {{ start }} {{ $t('tableFooter.info.to') }} {{ end }}
        {{ $t('tableFooter.info.of') }} {{ total }}{{ total > 9999 ? '+' : '' }}
        {{ $t('tableFooter.info.entries') }}</div>
    </div>
    <div v-if="isPaginationVisible" class="mb-2 zq--table-footer--pagination">
      <Pagination
          :activePage="page"
          :pages="pages"
          align="center"
          @update:activePage="updatePagenation"
      >
        <div slot="first-button">1</div>
        <div slot="last-button">
          {{ pages }}
        </div>
      </Pagination>

      <div v-if="!isDefaultTheme" class="position-relative">
        <ClFooterNav
            v-if="isFooterNav"
            :class="{'zq--fixed-sub-menu' : theme === 'main'}"
            :optionsProp="optionsProp"
            :page="page"
            :pageNumberProp="pageNumber"
            :startProp="start"
            :endProp="end"
            :entryProp="entry"
            :isGoToVisible="isGoToVisible"
            @updateItemPerPage="updateItemPerPage"
            @updatePagination="gotoPage"
        />
        <div class="zq--footer-options" @click="setIsFooterNav">
          <ClIcon
              cilIcon="cil-options"
              iconTypeProp="cil"
              v-if="!isFooterNav"
              imgIcon="actions-more"/>

          <ClIcon
              v-else
              cilIcon="fa-window-close"
              imgIcon="x"
              iconTypeProp="fa"
              class="competitions-icon-actions__cancel"
          />
        </div>
      </div>
    </div>
    <!--  SUB NAVIGATION  -->
    <ClFooterNav
        v-if="isDefaultTheme || isClFooter"
        :optionsProp="optionsProp"
        :page="page"
        :pageNumberProp="pageNumber"
        :startProp="start"
        :entryProp="entry"
        :endProp="end"
        :isGoToVisible="isGoToVisible"
        @updateItemPerPage="updateItemPerPage"
        @updatePagination="gotoPage"
    />
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import Pagination from './components/Pagination';
import ClIcon from '@/shared/UI/ClIcon';
import ClFooterNav from './components/ClFooterNav';

export default {
  name: 'Footer',
  components: {
    Pagination,
    ClIcon,
    ClFooterNav
  },
  props: {
    pages: Number,
    page: Number,
    total: Number,
    itemsPerPage: Number,
    optionsProp: Array,
    isGoToVisible: {
      type: Boolean,
      default() {
        return true
      }
    },
    isClFooter: {
      type: Boolean,
      default: false
    },
    isPaginationVisible: {
      type: Boolean,
      default() {
        return true
      }
    },
    disabled: Boolean,
  },
  data() {
    return {
      pageNumber: this.page,
      entry: this.itemsPerPage,
      start: 1,
      end: 0,
      isFooterNav: false
    };
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    isDefaultTheme() {
      return this.theme === 'default';
    }
  },
  methods: {
    gotoPage(val) {
      this.isFooterNav = false;
      this.pageNumber = val;
      this.$emit('updatePagenation', val);
    },
    updatePagenation(val) {
      this.isFooterNav = false;
      this.$emit('updatePagenation', val);
    },
    updateItemPerPage(val) {
      if (val !== this.entry) {
        this.entry = val;
        this.isFooterNav = false;
        this.$emit('updateItemPerPage', val);
      }
    },
    setIsFooterNav() {
      this.isFooterNav = !this.isFooterNav;
    }
  },
  mounted() {
    if (+this.total <= +this.itemsPerPage) {
      this.end = this.total;
    } else {
      this.end = this.page * this.itemsPerPage;
    }
    if (this.page > 1) this.start = (this.page - 1) * this.itemsPerPage;
    else this.start = 1;
  },
  watch: {
    page: {
      deep: true,
      handler: function () {
        if (this.total <= this.itemsPerPage) {
          this.end = this.total;
        } else {
          this.end = this.page * this.itemsPerPage;
        }
        if (this.end > this.total) {
          this.end = this.total;
        }
        if (this.page > 1) this.start = (this.page - 1) * this.itemsPerPage;
        else this.start = 1;
      },
    },
    total: {
      deep: true,
      handler: function (val) {
        if (val <= this.itemsPerPage) {
          this.end = val;
        } else {
          this.end = this.page * this.itemsPerPage;
        }
      }
    },
    itemsPerPage : {
      deep: true,
      handler: function (val) {
        if (+this.total <= val) {
          this.end = this.total;
        } else {
          this.end = this.page * val;
        }
      }
    },
  },
};
</script>
<style lang="scss">
.zq--table-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
  flex-wrap: wrap;

  .zq--table-footer--pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
  }
}
</style>
