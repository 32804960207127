<template>
  <div
      class="d-xl-flex d-md-flex d-flex d-lg-block align-items-center justify-content-lg-end justify-content-center flex-wrap flex-grow-1">
    <div class="zq--footer-sub-nav">
      <div class="mb-2 zq--footer-entries">
        <span>{{ $t('tableFooter.pagination.entries') }}</span>
        <ClSelect
          :options="optionsProp"
          :value-prop="entry"
          size="sm"
          :is-press-btn="false"
          @checkValue="checkEntry"
        />
      </div>
      <!--  Go TO PAGE    -->
      <div class="mb-2 go-to-page-container" v-if="isGoToVisible">
        <CInput
          :horizontal="{
              label: 'col-6',
              input: 'go-to-page-container-field',
            }"
          :label="$t('tableFooter.pagination.goTo')"
          v-model="pageNumber"
          @change="gotoPage"
          size="sm"
          class="goto-page-number"
          add-input-classes="goto-page-number-input"
        >
        </CInput>
        <i v-if="isDefaultTheme" class="fa fa-angle-right" aria-hidden="true" @click="gotoPage"></i>
        <CButton v-if="!isDefaultTheme" class="zq--responsive-button" color="" @click="gotoPage">Go</CButton>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ClSelect from '@/shared/components/formComponents/ClSelect';

export default {
  components: {
    ClSelect
  },
  props: {
    page: Number,
    pageNumberProp: Number,
    startProp: Number,
    endProp: Number,
    entryProp: Number,
    optionsProp: {
      type: Array,
      default() {
        return [10, 20, 40, 60, 80, 100, 200]
      }
    },
    isGoToVisible: Boolean
  },
  data() {
    return {
      entry: 10,
      end: 0,
      start: 1,
      pageNumber: 1
    }
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    isDefaultTheme() {
      return this.theme === 'default';
    }
  },
  created() {
    this.end = this.endProp;
    this.start = this.startProp;
    this.pageNumber = this.pageNumberProp;
    this.entry = this.entryProp;
  },
  methods: {
    checkEntry({value}) {
      this.entry = value;
    },
    gotoPage() {
      const isValid = !isNaN(Number(this.pageNumber.trim())) && this.pageNumber.trim() && Number(this.pageNumber.trim()) > 0;
      if (isValid) {
        this.$emit('updatePagination', parseInt(this.pageNumber));
      }
    },
  },
  watch: {
    endProp(val) {
      this.end = val;
    },
    startProp(val) {
      this.start = val;
    },
    pageNumberProp(val) {
      this.pageNumber = val;
    },
    entry: {
      deep: true,
      handler: function (val) {
        this.end = this.page * val;
        if (this.page > 1) this.start = (this.page - 1) * val;
        else this.start = 1;
        this.$emit('updateItemPerPage', val);
      },
    },
  }
}
</script>

<style lang="scss">
.zq--footer-modern {
  //flex: 1;
}

.zq--footer-sub-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  //max-width: 350px;
  flex-wrap: wrap;
  flex: 1;
}

.zq--footer-entries {

  .zq--select {
    .form-group {
      .form-control.is-valid {
        border: 1px solid #d8dbe0;
      }
    }

  }

}

.goto-page-number {
  label {
    width: 110px;
    text-align: end;
  }

  margin-bottom: 0;

  .goto-page-number-input {
    width: 60px;
  }
}


.go-to-page-container {
  position: relative;
  margin-left: 1rem;

  .goto-page-number.form-group.form-row {
    margin-bottom: 0;
  }

  @media (max-width: 420px) {
    margin-left: 0;
  }

  i {
    position: absolute;
    right: 6px;
    top: 6px;
    font-size: 16px;

    &:hover {
      cursor: pointer;
    }
  }


  .go-to-page-container-field {
    display: flex;
    justify-content: flex-end;
  }
}

.entry-select {
  min-width: 150px;
}

.entry-select-option {
  width: 70px;
}
</style>