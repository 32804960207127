<template>
  <div @click="clickHandler">
    <img v-if="theme === 'main'" :src="iconPath" width="24" height="24" alt="more">
    <template v-else>
      <CIcon v-if="iconTypeProp === 'cil'" :name="cilIcon"></CIcon>
      <i v-if="iconTypeProp === 'fa'" :class="`fa ${cilIcon}`"></i>
    </template>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  props: {
    cilIcon: String,
    imgIcon: String,
    iconTypeProp: {
      type: String,
      default: 'cil'
    }
  },
  data() {
    return {
      iconType: '',
    }
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    iconPath() {
      return require(`@/assets/icons/${this.imgIcon}.svg`)
    }
  },
  created() {
    // this.setIcon();
  },
  methods: {
    setIcon() {
      if (this.iconTypeProp) {
        this.iconType = this.iconTypeProp;
      } else {
        switch (this.theme) {
          case 'main': {
            this.iconType = 'img';
            break;
          }
          default : {
            this.iconType = 'cil';
          }
        }
      }
    },
    clickHandler() {
      this.$emit('click')
    },
  }
}
</script>